import { Grid, GridItem } from '@chakra-ui/react'
import { ImagesGridProps } from './types.ts'
import { GalleryImage } from './GalleryImage.tsx'

export const ImagesGrid = ({imageUrls}: ImagesGridProps) => {
	return (
		<Grid
			templateColumns="repeat(5, 1fr)"
			templateRows="repeat(2, 204px)"
			gap={4}
			rounded="lg"
			overflow="hidden"
		>
			<GridItem colSpan={3} rowSpan={2}>
				<GalleryImage src={imageUrls[0]}/>
			</GridItem>

			{imageUrls.slice(1, 5).map((url, index) => (
				<GridItem key={index + 1}>
					<GalleryImage src={url}/>
				</GridItem>
			))}
		</Grid>
	)
}

